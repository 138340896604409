import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/prphairloss.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'




const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PRPhairBeardResContPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section>
      <Container>

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">PRP Hair Restoration London</Itext>
</Iwrap>
      <Secondtext>
      What is Platelet-Rich Plasma (PRP) Hair Restoration Treatment?

      </Secondtext>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="prp beard restoration" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

         
              
              Platelet-Rich Plasma (PRP) London is a leading hair restoration treatment that encourages the growth of existing hair follicles, giving your hair a fuller and healthier appearance. Renowned to be a pioneering, safe, and natural non-surgical treatment for men and women suffering from hair thinning, hair loss, or pattern baldness. <br /> <br />

We realise how unpleasant hair loss and hair thinning may be. PRP hair restoration treatment London is a fantastic treatment to restore hair loss with the use of the healing properties of your own blood. 








              </Text>
            
            
             <br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>




        
        <Row className="align-items-center">
         
          <Col lg="12" md="9">
            <Box className=" pt-lg-0">
            <br /><br />
            <Secondtext>
            How does PRP hair restoration work?

      </Secondtext>
          
              <Text>
              PRP hair restoration treatment stimulates hair follicles, improves scalp blood circulation, and encourages tissue recovery. With the use of your own plasma, this one-of-a-kind  encourages the production of growth factors, leading to cell regeneration.<br /> <br />

Plasma is needed because it contains a lot of growth hormones, nutrients, and bioactive proteins that help with cell regeneration and tissue loss.<br /> <br />

Platelets are tiny blood  that circulate in our bloodstream and bind together when they detect injury to form blood clots (mesh) that halt bleeding and facilitate healing.<br /> <br />

PRP hair restoration treatment London uses a small needle to return these two beneficial growth-stimulating components of the blood to targeted places of the scalp. They work in tandem with the body's natural repair mechanisms to stimulate and repair damaged/weak hair follicles, resulting in hair restoration and significant hair growth advantages, as well as a reduction in hair loss.
 <br /> <br />

              </Text>


              <Secondtext>
              PRP hair restoration treatment has 3 steps, which is as follows:

      </Secondtext>
          
              <Text>
              1.	Blood will be drawn from your arm and stored in a specific tube in a sample size amount. The tube is then placed in a centrifuge.<br /> <br />
2.	The blood sample is centrifuged. A centrifuge is used to separate the platelets from the rest of the blood , resulting in your own Platelet Rich Plasma.<br /> <br />
3.	Using the prepared syringes, the highly concentrated PRP is reinserted into areas of the scalp where hair loss and thinning are present.
 <br /> <br />
 

              </Text>


              <Secondtext>
              PRP Hair Restoration Results 

      </Secondtext>
          
              <Text>
              The use of platelet rich plasma (PRP) injections as a successful type of aesthetic treatment for hair loss and hair follicle regeneration is a relatively new discovery, with numerous scientific and academic studies supporting and confirming the use of PRP injections as a successful type of aesthetic treatment for hair loss and hair follicle restoration.<br /> <br />

The individual's biological and genetical features, lifestyle variables, other causes of hair loss, hair texture and density, and other aspects all influence the final outcome of any treatment.<br /> <br />

Before we begin treatment, we will do a thorough evaluation to create a personalised treatment plan that is tailored to your specific requirements and abilities.
<br /> <br />


              </Text>


              <Secondtext>
              PRP treatment sessions


      </Secondtext>
          
              <Text>
              

              For a full course treatment, 4-6 sessions are recommended, depending on the individual.<br /> <br />

One session should be done once a month, followed by monthly maintenance sessions, to achieve the best effects.<br /> <br />

The number of sessions required and the expected outcomes vary by individual; this will be discussed during your evaluation and consultation, which is included with every treatment.
<br /> <br />


              </Text>


              <Secondtext>
              Why choose PRP hair restoration treatment?


      </Secondtext>
          
              <Text>
              

              PRP Hair Restoration Treatment is a cutting-edge treatment for thinning hair and hair loss that is one-of-a-kind. It uses your own  to help restore hair growth without the need for surgery or the use of any external medications.<br /> <br />

PRP treatment begins with the collection of a sample of your own blood, which is then centrifuged to form platelet-rich plasma, which stimulates the production of growth factors and other healing-promoting compounds in your own body. PRP is injected into the scalp to stimulate hair follicles in areas where hair loss and thinning are present.<br /> <br />

PRP can not only assist to prevent hair loss, but it can also aid in the restoration of hair. People with alopecia or other immune issues, as well as those who have received hair transplants, can have remarkable results.
<br /> <br />



              </Text>


              <Secondtext>
              What are the benefits of London PRP hair restoration treatment?


      </Secondtext>
          
              <Text>
              

              Using your own PRP ensures that only a natural liquid is penetrating your scalp, encouraging the creation of new hair  and reducing hair loss.<br /> <br />

It's completely painless because it's a non-surgical, minimally intrusive technique. We won't inject anything foreign; we'll only use your own , so there's no risk of an allergic reaction or the substance being rejected.<br /> <br />

There is no need for recovery time after a PRP session; you can return to your daily routine right away. PRP treatment takes less than 10 minutes and appointments are only 30 minutes long.<br /> <br />

PRP is injected into the scalp with very small needles, causing minor discomfort. Numbing cream is also available upon request.<br /> <br />

You may notice long-term effects. Although the full results of the treatment may take some time to appear, many patients find this technique to be effective.<br /> <br />





              </Text>


              <Secondtext>
              Is PRP hair restoration treatment right for me?


      </Secondtext>
          
              <Text>
              

              PRP hair restoration treatment can help patients who are experiencing hair thinning or loss. If you suffer from alopecia or other immune system issues, the results can be life-changing.<br /> <br />

We'll have an in-depth conversation with you to better understand your issues and determine whether or not you'll be able to achieve what you want. We're here to help you along your individual path.
<br /> <br />





              </Text>
            
            
              <Secondtext>
              Who is suitable for the PRP hair restoration treatment?


      </Secondtext>
          
              <Text>
              
              -	Patients who experience hair loss, hair thinning or pattern baldness.  <br />
-	Men and women suffering from all forms of alopecia.  <br />
-	Ineffective on patients bald as the hair follicles have to be present in order for PRP to be its best. <br />
-	Patients aged 18 and over. <br /> 
-	Suitable for patients who have had or are having a hair transplant.  <br />
-	Patients who do not respond to hair growth medications and other hair loss therapies.
              </Text>
            
           
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default PRPhairBeardResContPage;
