import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgContent from "../../assets/image/jpeg/prp.jpg";
import Himage from '../../assets/image/jpeg/home-bg2.jpg';

const ImgContainer = styled.div`
  margin-top: 20px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;


const Newbut = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-top: auto;
margin-bottom: 4em;
padding-top: 1em;
padding-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
opacity: 0.4;

color: #fff;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
max-width: 100%;
    display: inline-block;
`;




const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: hsla(0, 0%, 100%, 0.8);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: hsla(0, 0%, 100%, 0.8);
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;






const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.1;


`;



const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 4.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 36px;
          text-align:center;
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 0px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;



const Secti = styled.section`


position: relative;
padding-top:6em;
background-color: #9EAEB2;


@media screen and (max-width: 767px)
{
  padding-top:2em;
 }

`;




const Sect = styled.section`

  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const PRPHairResHeroPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Secti>
    
     <Imagi />
   
    <Section

      className="position-relative"
      py={["50px", null, "130px", null, "230px"]}
    >
      <Container>
        <Row className="align-items-center">
      
          <Col lg="6" className="order-lg-1 mt-3 mt-lg-0">
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
          <Iwrap>

          
          <Itext as="h1">PRP Hair Restoration</Itext>
          </Iwrap>
                <Secondtext>
                Achieve noticeable fuller & thicker hair 
                </Secondtext>

                <Thirdtext>
                Natural & non invasive prodecure
                </Thirdtext>
               
              </div>
            </div>
          
          </Col>

          <Col lg="6" className=" position-static order-lg-2">
            <ImgContainer className=" pl-lg-5">
              <img
                src={imgContent}
                alt="non surgical nose job london"
                className="img-fluid"
                width="800px"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
                css={`
                  box-shadow: ${({ theme }) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 10px;
                `}
              />
            </ImgContainer>
          </Col>
        </Row>
   
      </Container>
     
    </Section>
    </Secti>
    
  </>
);

export default PRPHairResHeroPage;
