import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { breakpoints } from "../../utils";
import trust from "../../assets/image/png/trust.svg"
import google from "../../assets/image/png/google-reviews.svg"
import { device } from "../../utils";
import imgC1 from "../../assets/image/png/test1.png";
import imgC2 from "../../assets/image/png/test2.png";
import imgC3 from "../../assets/image/png/test3.png";
import imgC4 from "../../assets/image/png/test4.png";
import imgC5 from "../../assets/image/png/test5.png";
import imgC6 from "../../assets/image/png/test6.png";
import imgC7 from "../../assets/image/png/test7.png";
import imgC8 from "../../assets/image/png/test8.png";




const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;
      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;


const SectionStyled = styled(Section)`
  background-color: #f1f1f1;
  border-bottom: 1px solid #ededf4;
  padding-top:5px;
  padding-bottom: 50px;

  @media ${device.md} {
    padding-top:5px;
  
  }
`;


const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
   
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="#fff"
    border="10px solid #FFD9CF"
    borderColor="border"
    p="30px"
    mx="20px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={2}>
      {children}
    </Text>
    <Box className={``}>
   
      <div className="flex-grow-1">
        <Title variant="card">
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
    <Box className="" mr={0}>
        <img src={image} alt="" className="img-fluid" width="50%" />
      </Box>
  </Box>
);

const AdvRhino = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    autoplay:true,
    cssEase: "linear",
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
   
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
   <Section bg="#fff" className="pb-md-5 pt-md-5  "  py={5}>
        <Container  className="pb-md-3  pt-md-3" 
        
        >
            <Row className="align-items-center">
                <Col lg="6" className="order-lg-2">
                <Iwrap>

          
          <Itext as="h2">Dermamina Reviews</Itext>
          </Iwrap>
                <Secondtext>
               What our customers think
                </Secondtext>

          
                </Col>
     
            <Col lg="6" className="order-lg-1"
          
            >
              <SliderStyled {...slickSettings}>

              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">   <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Daniela"
                    image={trust}
                  >
        Mina was so professional and made me feel so at ease throughout my whole procedure..
                  </ContentCard>
                </Box> </a>
                
                <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">   <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Tom"
                    image={trust}
                  >
              Extremely happy with my end result. Didn’t know that it was going to look this good..
                  </ContentCard>
                </Box> </a>

                <a href="https://uk.pilot.com/review/www.dermamina.com" target="_blank">    <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Faiza"
                    image={trust}
                  >
           Mina made me feel comfortable from the beginning to the very end. She ensured I knew everything regarding the procedure..
                  </ContentCard>
                </Box> </a>


                <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">        <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Amella"
                    image={trust}
                  >
            So so Grateful for Mina and her Assistant. Incredible results, couldn’t be happier..
                  </ContentCard>
                </Box> </a>
              </SliderStyled>
            </Col>
          </Row>

      

        </Container>
        </Section>
    </>
  );
};

export default AdvRhino;
